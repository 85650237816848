import React from 'react';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'components/ui/link';
import { transformImages } from 'helpers/transformImages';
import { useSourceParams } from 'hooks/useSourceParams';
import arrow from 'img/ui/arrow_white.svg';

const UserExperienceDesign = () => {
  const banner = useSourceParams('banner');
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query UserExperienceDesign {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "user-experience/user-experience-design" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2>What is User Experience Design? </h2>

      <p>
        In the most simple terms,{' '}
        <Link
          href="https://medium.com/@sagarajkt/top-tips-to-improve-user-experience-design-7345948bc12"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          UX design
        </Link>{' '}
        is the process of increasing user satisfaction and making sure that the services you provide
        to your customers genuinely deliver value. This is often measured through metrics like
        mobile app use, the number of returning visitors, conversion rates, and others.
      </p>
      <p>
        User Experience Designer & Information Architecture pioneer Peter Morville does a great job
        at explaining UX by breaking it into 7 key areas, known as the{' '}
        <Link
          href="http://semanticstudios.com/publications/semantics/000029.php"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          UX Honeycomb
        </Link>
        .
      </p>
      <Img
        fluid={images.semanticstudios.node.childImageSharp.fluid}
        alt="7 key areas"
        title="7 key areas - Semantic Studios"
      />
      <p>
        Source:{' '}
        <Link
          href="http://semanticstudios.com/user_experience_design/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Semantic Studios
        </Link>
      </p>
      <p>
        According to this theory, the quality of user experience design lies in the following
        qualities: usefulness, usability, desirability, accessibility, findability, credibility, and
        value for business and its sponsors.
      </p>
      <p>
        That being said, let’s now see how you can improve the{' '}
        <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
          user experience
        </Link>{' '}
        for your service.
      </p>
      <h2>UX Best Practices - What Does it Take to Create Good UX Design? </h2>

      <p>
        Effective UX is all about catering your design to your audience so that they feel confident
        and comfortable while meeting their end goal. What’s more, if your users find your interface
        easy and satisfying to use, it’s less probable they will leave you for a competitor.
      </p>
      <p>
        Below we share several top UX best practices – if you’re a designer, make sure to cross them
        off your checklist!
      </p>
      <h3>Understand who your audience is </h3>

      <p>
        UX design should start with gaining an intimate understanding of who your users are. There
        are several methods of learning about their needs and expectations, which include running
        surveys, talking to them directly, or collecting insights from analytics software. You
        should always design by looking at your product through the eyes of your users; ask
        yourself, “what are they trying to get from our product?”
      </p>
      <p>
        Here are a few questions you should aim to answer in the discovery phase of your product:
      </p>
      <ul>
        <li>What problems are the users looking to solve?</li>

        <li>How can this product address their goals and needs?</li>

        <li>What makes people choose this product over competing solutions on the market?</li>
      </ul>
      <h3>Seek simplicity and familiarity </h3>

      <p>
        Have you ever visited a website that has a completely different design than what you’re used
        to? Perhaps it doesn&apos;t have the typical hamburger menu, or there aren’t any tabs at the
        top. Odds are, you got frustrated and might have left the site altogether.
      </p>
      <p>
        Your audience doesn’t want to relearn how to use an app or a website, and, thus, it’s your
        job not to entirely reinvent the wheel when it comes to user experiences. Rely on existing
        behavioral patterns to design solutions your users are familiar with.
      </p>
      <p>
        Simplicity and familiarity in your designs and features will help your users achieve their
        goals faster and more efficiently.
      </p>
      <h3>Observe how your users interact with your design </h3>

      <p>
        You can’t know what to alter or improve unless you know what the user experience is truly
        like. Use session recordings or in-person usability testing to find out how your audience
        interacts with the design you have created.
      </p>
      <p>
        What do they like? What do they struggle with? How can you make elements of your design more
        intuitive (and, thus, create a faster and more efficient experience)?
      </p>
      <a className="action-banner" href={banner.link} target="_blank" rel="noopener noreferrer">
        If you want to observe how your users interact with your website or service, you can do so
        by signing up for LiveSession for free.
      </a>
      <h3>Make sure the entire user flow is consistent </h3>

      <p>
        A user flow shows all the steps that a user has to take to achieve their goals. When you
        consider how a person will move throughout your website or app, you should ask yourself:
      </p>
      <ul>
        <li>Do they reach their goal at the end? </li>

        <li>Do they encounter any interruptions along the way? </li>

        <li>Are there any steps that could be simplified or omitted altogether?</li>
      </ul>
      <p>
        <Link href="/user-experience/user-journey-map/" target="_blank" rel="noopener noreferrer">
          User journey mapping
        </Link>{' '}
        is one way that you can narrow down any underperforming areas in user flow and address it in
        your UX design.
      </p>
      <Img
        fluid={images.uxdesign.node.childImageSharp.fluid}
        alt="User Flow Design"
        title="User journey mapping"
      />
      <p>
        Source:{' '}
        <Link
          href="https://uxdesign.cc/when-to-use-user-flows-guide-8b26ca9aa36a"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          UXdesign
        </Link>
      </p>
      <h3>Prioritize readability (and accessibility) </h3>

      <p>
        The text you display in your product needs to be legible and promote maximum readability.
        Readability is the ease at which the reader can understand the written text. While you may
        have text that is grammatically correct, it doesn’t necessarily mean it’s easily readable.
        Aim at making it easy to understand for all age groups by using a familiar language.
      </p>
      <p>
        Depending on whom your product is targeted at, you should also consider designing
        interactions that extend beyond the screen – for instance, by creating a voice user
        interface (VUI) for the visually impaired. If you wish to explore this further, we recommend
        taking a look at the accessibility guidelines described on the{' '}
        <Link
          href="https://www.interaction-design.org/literature/topics/accessibility#:~:text=Both%20overlap%20and%20are%20vital,also%20key%20distinctions%20between%20them.&text=Accessibility%2C%20on%20the%20other%20hand,e.g.%2C%20using%20assistive%20devices)."
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Interaction Design Foundation
        </Link>{' '}
        site.
      </p>
      <h2>4 User Experience Design Examples</h2>

      <p>
        The biggest companies often lead in good user experience design. Just think of it – if they
        were able to dominate the market and become a global favorite among users, they must have
        done something right!
      </p>
      <p>
        That being said, UX design doesn’t only apply to big companies or brands. It just means that
        they’ve done a phenomenal job instituting it in their products, and it’s proven itself
        successful. If you’re currently looking for ways to grow your company’s position, good UX
        design can help you connect with your customers and foster brand loyalty.
      </p>
      <p>Below, we share 4 examples of the best UX design.</p>
      <h3>1. Google Search Engine </h3>
      <p>
        Google just had its 22<sup>nd</sup> birthday on September 4. Today, it is by far{' '}
        <Link
          href="https://userguiding.com/blog/good-ux-examples/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          the world’s largest search engine
        </Link>{' '}
        with over 3.5 billion searches made. If there’s one secret to Google’s search engine
        success, it’s UX design. All you have to do is type your inquiry into the search bar and hit
        the ‘Enter’ key. Before you know it, thousands of hits matching your inquiry pop up.
      </p>
      <Img
        fluid={images.hackermoon.node.childImageSharp.fluid}
        alt="Google example - autocomplete"
        title="Autocomplete"
      />
      <p>
        Source:{' '}
        <Link
          href="https://hackernoon.com/design-auto-complete-system-in-python-8fab1470cd92"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Hackernoon{' '}
        </Link>
      </p>
      <p>
        As the simplest and fastest search tool ever created, customers know exactly what to expect.
        In the past 20 years, Google’s home page has varied little in its design, and in all
        honesty, it has only gotten simpler. By removing unnecessary links, even new users cannot be
        confused by what they need to do. Click, type, and search. It’s about as easy and familiar
        as it gets.
      </p>
      <h3>2. Facebook</h3>

      <p>
        Facebook was launched on February 2, 2004 from a Harvard dorm by Mark Zuckerberg. Today,
        there are over{' '}
        <Link
          href="https://www.statista.com/statistics/264810/number-of-monthly-active-facebook-users-worldwide/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          2.7 billion
        </Link>{' '}
        monthly active users. Learning how to provide a good UX for each of these users has been
        challenging for Facebook, but they’ve risen to the occasion.
      </p>
      <p>
        Over the years, they’ve added new features about the user’s locations, languages, interests,
        activities, potential friends, groups, and more to help them connect with their network. As
        they’ve added more features, they’ve increased their user experience and the value they
        offer as a social media network.
      </p>
      <Img
        fluid={images.techcrunch.node.childImageSharp.fluid}
        alt="Facebook example - navigation"
        title="Navigation"
      />
      <p>
        Source:{' '}
        <Link
          href="https://consent.yahoo.com/v2/collectConsent?sessionId=3_cc-session_8749294b-996c-49c2-9a07-e0b0e796a0d6"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Techcrunch
        </Link>
      </p>
      <p>
        Every few years, Facebook also updates its interface to ensure that it’s serving its
        audience effectively.
      </p>
      <h3>3. PayPal </h3>

      <p>
        PayPal was officially established in December 1998 as Confinity, which developed security
        software for handheld devices. However, with little success in that field, it switched its
        focus to a digital wallet. Its first version as an electronic payments system launched in
        1999. Today, PayPal is the most trusted and secure online payment method.
      </p>
      <Img
        fluid={images.opayo.node.childImageSharp.fluid}
        alt="PayPal example - summary"
        title="Summary"
      />
      <p>
        Source:{' '}
        <Link
          href="https://www.opayo.co.uk/support/12/36/linking-paypal-to-your-live-account"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Opayo
        </Link>
      </p>
      <p>
        However, the site was complex before they{' '}
        <Link
          href="http://www.nickyvandewater.com/paypal-web-redesign"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          released a redesign
        </Link>{' '}
        of both their websites and optimized them for mobile in May 2014. The redesign launched in
        24 countries to 154 million customers and sought to cut down on the number of words and
        links that overcomplicated the experience for its audience. Their main priority was to
        reduce the time that users needed to spend on their website. They also incorporated a
        mobile-first methodology so their site could be used on smartphones as well.
      </p>
      <h3>4. Starbucks </h3>

      <p>
        Starbucks is another user experience design example worth looking at. It has come a long way
        since its founding in 1971. On September 23, 2009, Starbucks launched its app, which is now
        the most regularly used loyalty rewards app amongst major restaurant chains. Nearly half of
        Starbucks drinkers (
        <Link
          href="https://www.retailcustomerexperience.com/news/starbucks-is-top-mobile-loyalty-app-with-consumers-reveals-survey/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          48 percent
        </Link>
        ) use the mobile app, and its digital engagement is largely credited for its success.
      </p>
      <p>
        Part of this is the user-friendly mobile experience and personalization. The app makes
        online ordering easy by recording purchase histories and patterns. By narrowing down the
        menu items to a shortlist of what the customer has previously enjoyed, it makes it a more
        streamlined experience. If you’re a Starbucks regular, it’s unlikely that you’re looking at
        the full menu every single time you order. You’re likely ordering one of three or four
        drinks that you’ve had previously. The Starbucks app also highlights new drinks on the
        “Featured” tab. This way, their customers won’t miss out on anything they may want to try
        even if they’re creatures of habit.
      </p>
      <Img
        fluid={images.starbucks.node.childImageSharp.fluid}
        alt="Starbucks example - app"
        title="Easy to use"
      />
      <p>
        Source:{' '}
        <Link
          href="https://www.starbucks.com/coffeehouse/mobile-apps"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Starbucks
        </Link>
      </p>
      <p>
        After you’ve selected your drink, you’ll see suggested food items that may pair well as well
        as suggested locations for where you may want to pick up your order. Starbucks’ geolocation
        feature highlights the closest physical location to you. All the while, you’ll be earning
        rewards points (“stars”) for your purchases, so you can get free drinks or food items
        periodically.
      </p>
      <p>
        The Starbucks app is quick and easy to use, which is why it’s been so successful with its
        customers.
      </p>
      <h2>Final thoughts</h2>

      <p>
        User experience helps to increase user satisfaction and usability. You can have a phenomenal
        product, but if its information and features aren’t laid out in the right way, your audience
        won’t be able to use it effectively. Companies like PayPal sought to redesign their user
        experience by seeking simplicity, which ultimately boosted their presence on mobile
        platforms. Starbucks, on the other hand, took a look at their audience and implemented
        personalization features that increased its app’s usability.
      </p>
      <p>
        Ultimately, when you focus on user experience design, you increase your company’s potential
        and open yourself up to the opportunity of providing greater value to your customers.
      </p>
      <div className="next-chapter">
        <Link href="/user-experience/user-journey-map/">
          <div>
            <p>Chapter 4</p>
            <p>How to develop a user journey map in 6 simple steps</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(UserExperienceDesign);
